import React, { MouseEvent } from 'react';

import styles from './Video.module.scss';

type Props = {
  title: string;
  videoId: string;
  coverUrl: string;
  onClick: (e: MouseEvent) => void;
};

const Video = ({ title, videoId, coverUrl, onClick }: Props) => {
  return (
    <div className={styles.root}>
      <a
        href={`https://www.youtube.com/watch?v=${videoId}`}
        target="_blank"
        rel="nofollow noopener"
        className={styles.link}
        onClick={onClick}
      >
        <h3 className={styles.heading}>{title}</h3>
        <div className={styles.play}>
          <span className={styles.iconWrapper}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              className={styles.icon}
            >
              <path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z" />
            </svg>
          </span>
        </div>
      </a>
      <div
        style={{ backgroundImage: `url(${coverUrl})` }}
        className={styles.image}
      ></div>
      <div className={styles.backdrop}></div>
    </div>
  );
};

export default Video;
