import { Link } from 'gatsby';
import React, { Component, ReactNode } from 'react';

import styles from './Modal.module.scss';

type Props = {
  children: ReactNode;
  onHide: () => void;
  canGoNext?: boolean;
  onNext?: () => void;
  canGoPrev?: boolean;
  onPrev?: () => void;
  isVideo?: boolean;
  title?: string;
};

class Modal extends Component<Props> {
  componentWillUnmount() {
    document.body.classList.remove(styles.modalOpen);

    window.removeEventListener('click', this.hide);
    document.removeEventListener('keyup', this.onKeyUp);
    document.removeEventListener('keydown', this.onKeyDown);
  }

  componentDidMount() {
    document.body.classList.add(styles.modalOpen);

    window.addEventListener('click', this.hide);
    document.addEventListener('keyup', this.onKeyUp);
    document.addEventListener('keydown', this.onKeyDown);
  }

  onKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      this.props.onHide();
    }
  };

  onKeyDown = (e: KeyboardEvent) => {
    const { onPrev, onNext, canGoPrev, canGoNext } = this.props;
    switch (e.key) {
      case 'ArrowLeft':
        if (canGoPrev && onPrev) onPrev();
        break;
      case 'ArrowRight':
        if (canGoNext && onNext) onNext();
        break;
      default:
        break;
    }
  };

  hide = (e: MouseEvent) => {
    if ((e.target as Element).classList.contains(styles.root)) {
      this.props.onHide();
    }
  };

  render() {
    const { canGoPrev, canGoNext, isVideo, title } = this.props;

    const contentStyles = [styles.content];

    if (isVideo) {
      contentStyles.push('embed-responsive embed-responsive-16by9');
    }

    return (
      <div>
        <div className={styles.root} tabIndex={-1} role="dialog">
          <div className={styles.dialog} role="document">
            <div className={contentStyles.join(' ')}>
              <div className={styles.dismissWrapper}>
                <button
                  type="button"
                  className={styles.dismiss}
                  onClick={this.props.onHide}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                    className={styles.dismissIcon}
                  >
                    <path d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" />
                  </svg>
                </button>
              </div>
              {canGoPrev && (
                <button
                  type="button"
                  className={styles.prev}
                  onClick={this.props.onPrev}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    className={styles.prevIcon}
                  >
                    <path d="M229.9 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L94.569 282H436c6.627 0 12-5.373 12-12v-28c0-6.627-5.373-12-12-12H94.569l155.13-155.13c4.686-4.686 4.686-12.284 0-16.971L229.9 38.101c-4.686-4.686-12.284-4.686-16.971 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971L212.929 473.9c4.686 4.686 12.284 4.686 16.971-.001z" />
                  </svg>
                </button>
              )}
              {canGoNext && (
                <button
                  type="button"
                  className={styles.next}
                  onClick={this.props.onNext}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    className={styles.nextIcon}
                  >
                    <path d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z" />
                  </svg>
                </button>
              )}
              {this.props.children}
              {title && <h2 className={styles.title}>{title}</h2>}
            </div>
          </div>
        </div>
        <div className={styles.backdrop} onClick={this.props.onHide}></div>
      </div>
    );
  }
}

export default Modal;
