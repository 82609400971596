import React from 'react';
import styles from './Photo.module.scss';

type Props = {
  src: string;
  onLoad?: () => void;
  position?: string;
};

const Photo = ({ src, onLoad, position }: Props) => {
  return (
    <img
      src={src}
      className={styles.root}
      onLoad={onLoad}
      style={{
        objectPosition: position,
      }}
    />
  );
};

export default Photo;
