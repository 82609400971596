import React, { Component } from 'react';

import styles from './Section.module.scss';

type Props = {
  id?: string;
  className?: string;
  getRef: (ref: React.RefObject<HTMLDivElement>, index: number) => void;
  index: number;
  shown?: boolean;
  animation?: 'fadeIn' | 'fadeInUp';
};

type State = {
  isAnimationEnded: boolean;
};

class Section extends Component<Props, State> {
  block = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      isAnimationEnded: false,
    };
  }

  componentDidMount() {
    this.props.getRef(this.block, this.props.index);
  }

  render() {
    const { id, className, shown } = this.props;
    const { isAnimationEnded } = this.state;

    let animation = styles.fadeInUp;
    if (this.props.animation === 'fadeIn') animation = styles.fadeIn;

    const rootStyles = [styles.root];
    if (className) rootStyles.push(className);
    if (shown) rootStyles.push(styles.show);
    if (shown && !isAnimationEnded) rootStyles.push(animation);

    return (
      <div
        ref={this.block}
        id={id}
        className={rootStyles.join(' ')}
        onAnimationEnd={() => {
          this.setState({ isAnimationEnded: true });
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Section;
