import React, { Component } from 'react';
import { Link } from 'gatsby';

import styles from './Header.module.scss';

type Props = {
  siteTitle: string;
  activeIndex: number;
  activeSlug?: string;
  homeUrl: string;
  nav: any;
  contact: any;
  languages: [];
};

type State = {
  isOpened: boolean;
};

class Header extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpened: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    if (this.state.isOpened) {
      if (window.innerWidth >= 768) {
        document.body.classList.remove('overflow-hidden');
      } else {
        document.body.classList.add('overflow-hidden');
      }
    }
  }

  render() {
    const {
      siteTitle,
      activeSlug,
      homeUrl,
      nav,
      contact,
      languages,
    } = this.props;

    const rootStyles = [styles.root];
    if (this.state.isOpened) rootStyles.push(styles.isOpened);

    const navbarContactStyles = [styles.navbarContact];
    if (activeSlug === 'contact') navbarContactStyles.push(styles.active);

    return (
      <header className={rootStyles.join(' ')}>
        <nav className={styles.navbar}>
          <Link to={homeUrl} className={styles.navbarBrand}>
            {siteTitle}
          </Link>
          <div className={styles.navbarMain}>
            <ul className={styles.navbarNav}>
              {nav.items.map((item: any) => {
                const navItemStyles = [styles.navItem];
                if (item.slug === activeSlug) navItemStyles.push(styles.active);
                return (
                  <li key={item.slug} className={navItemStyles.join(' ')}>
                    <a
                      href={item.to}
                      onClick={(e) => {
                        e.preventDefault();

                        this.setState({ isOpened: false });
                        document.body.classList.remove('overflow-hidden');

                        const element = document.getElementById(item.slug);
                        if (element) {
                          if (element.offsetHeight > window.innerHeight) {
                            element.scrollIntoView({
                              behavior: 'smooth',
                              block: 'start',
                              inline: 'start',
                            });
                          } else {
                            element.scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                              inline: 'start',
                            });
                          }
                        }
                      }}
                    >
                      {item.title}
                    </a>
                  </li>
                );
              })}
            </ul>
            <div className={styles.navbarContactWrapper}>
              <a
                href={contact.to}
                className={navbarContactStyles.join(' ')}
                onClick={(e) => {
                  e.preventDefault();

                  this.setState({ isOpened: false });
                  document.body.classList.remove('overflow-hidden');

                  const element = document.getElementById('contact');
                  if (element) {
                    element.scrollIntoView({
                      behavior: 'smooth',
                      block: 'end',
                      inline: 'start',
                    });
                  }
                }}
              >
                {contact.title}
              </a>
            </div>
            <div className={styles.navbarLanguageSwitcher}>
              {languages
                .filter((language: any) => language.active)
                .map((language: any, index) => (
                  <Link
                    key={index}
                    to={language.url}
                    className={styles.navbarCurrentLanguage}
                  >
                    {language.key.toUpperCase()}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      className={styles.navbarCurrentLanguageIcon}
                    >
                      <path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" />
                    </svg>
                  </Link>
                ))}

              <ul className={styles.navbarLanguages}>
                {languages
                  .filter((language: any) => !language.active)
                  .map((language: any, index) => (
                    <li key={index} className={styles.navbarLanguage}>
                      <Link to={language.url}>
                        {language.key.toUpperCase()}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </nav>
        <button
          type="button"
          className={styles.navbarToggle}
          onClick={() => {
            if (this.state.isOpened) {
              this.setState({ isOpened: false });
              document.body.classList.remove('overflow-hidden');
            } else {
              this.setState({ isOpened: true });
              document.body.classList.add('overflow-hidden');
            }
          }}
        >
          {this.state.isOpened ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              className={styles.navbarToggleIcon}
            >
              <path d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              className={styles.navbarToggleIcon}
            >
              <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" />
            </svg>
          )}
        </button>
      </header>
    );
  }
}
export default Header;
