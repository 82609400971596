import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import { CSSTransition } from 'react-transition-group';
import { IntlProvider, FormattedMessage } from 'react-intl';
import 'intl';

import '../../style.scss';
import styles from './Layout.module.scss';

import Header from '../header/Header';
import Section from '../section/Section';
import Photo from '../photo/Photo';
import Biography from '../biography/Biography';
import VideoTile from '../video/Video';
import AudioTile from '../audio/Audio';
import Quote from '../quote/Quote';
import Gallery from '../gallery/Gallery';
import Contact from '../contact/Contact';
import Modal from '../modal/Modal';
import modalStyles from '../modal/Modal.module.scss';
import Player from '../player/Player';
import playerStyles from '../player/Player.module.scss';

type Props = {
  data: any;
  headerNav: any;
  headerContact: any;
  messages: any;
  location: any;
  currentLanguage: string;
};

type State = {
  activeIndex: number;
  lastActiveIndex: number;
  videoModal: {
    isShown: boolean;
    videoId: null | string;
  };
  player: {
    isShown: boolean;
    name?: string;
    file?: string;
  };
};

class Layout extends Component<Props, State> {
  blocks: any[];
  mounted: boolean;

  constructor(props: any) {
    super(props);
    this.state = {
      activeIndex: 0,
      lastActiveIndex: 0,
      videoModal: {
        isShown: false,
        videoId: null,
      },
      player: {
        isShown: false,
      },
    };
    this.blocks = [];
    this.mounted = false;
  }

  calculateActiveIndex = () => {
    if (typeof window !== 'undefined') {
      let activeIndex = 0;
      let lastActiveIndex = 0;

      const blocksPositions: any[] = [];

      this.blocks.forEach((block) => {
        if (block.current.offsetHeight >= window.innerHeight) {
          blocksPositions.push([
            block.current.offsetTop,
            block.current.offsetTop + block.current.offsetHeight,
          ]);
        } else {
          blocksPositions.push([
            block.current.offsetTop -
              (window.innerHeight - block.current.offsetHeight),
            block.current.offsetTop + block.current.offsetHeight,
          ]);
        }
      });

      blocksPositions.forEach((blockPositions, index) => {
        if (
          window.scrollY >= blockPositions[0] &&
          window.scrollY <= blockPositions[1]
        ) {
          activeIndex = index;
        }
      });

      for (let i = 0; i < this.blocks.length; i++) {
        lastActiveIndex = i;

        if (
          window.scrollY <
          this.blocks[i].current.offsetTop +
            this.blocks[i].current.offsetHeight -
            window.innerHeight * 0.75
        ) {
          break;
        }
      }

      if (activeIndex !== this.state.activeIndex) {
        this.setState({ activeIndex });
      }

      if (lastActiveIndex > this.state.lastActiveIndex) {
        this.setState({ lastActiveIndex });
      }
    }
  };

  componentDidMount() {
    this.mounted = true;

    this.calculateActiveIndex();

    window.addEventListener('scroll', this.calculateActiveIndex);

    document.body.classList.remove('overflow-hidden');
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.calculateActiveIndex);
  }

  getRef = (ref: any, i: any) => {
    this.blocks[i] = ref;
  };

  render() {
    const { activeIndex, lastActiveIndex, player } = this.state;

    const { currentLanguage } = this.props;

    const {
      site,
      content,
      videos,
      audios,
      galleryPhotos,
      galleryThumbnails,
    } = this.props.data;

    // const url = location.pathname;
    const {
      availableLanguages,
      defaultLanguage,
    } = this.props.data.site.siteMetadata;

    const homeUrl = `/${currentLanguage}`.replace(`/${defaultLanguage}`, '/');

    const languages: any = [];
    availableLanguages.forEach((availableLanguage: any) => {
      const language: any = {};

      language.key = availableLanguage;

      if (availableLanguage === currentLanguage) {
        language.active = true;
      } else {
        language.active = false;
      }

      if (availableLanguage === defaultLanguage) {
        language.url = '/';
      } else {
        language.url = `/${availableLanguage}`;
      }

      languages.push(language);
    });

    return (
      <IntlProvider locale={currentLanguage} messages={this.props.messages}>
        <div>
          <Header
            siteTitle={site.siteMetadata.title}
            activeIndex={activeIndex}
            activeSlug={this.blocks[activeIndex]?.current.id}
            homeUrl={homeUrl}
            languages={languages}
            nav={this.props.headerNav}
            contact={this.props.headerContact}
          />
          <main className={styles.main}>
            {content.edges.map(({ node }: any, i: any) => {
              if (node.frontmatter.slug === 'photo') {
                return (
                  <Section
                    key={node.id}
                    index={i}
                    getRef={this.getRef}
                    shown={i <= lastActiveIndex}
                    animation="fadeIn"
                  >
                    <Photo
                      src={node.frontmatter.file.publicURL}
                      position={node.frontmatter.position}
                    />
                  </Section>
                );
              }

              if (node.frontmatter.slug === 'biography') {
                return (
                  <Section
                    key={node.id}
                    id={node.frontmatter.slug}
                    index={i}
                    getRef={this.getRef}
                    shown={i <= lastActiveIndex}
                  >
                    <Biography excerpt={node.excerpt} content={node.html} />
                  </Section>
                );
              }

              if (node.frontmatter.slug === 'recommendation') {
                return (
                  <Section
                    key={node.id}
                    id={`${node.frontmatter.slug}${i}`}
                    index={i}
                    getRef={this.getRef}
                    shown={i <= lastActiveIndex}
                  >
                    <Quote
                      content={node.html}
                      source={node.frontmatter.source}
                      authorPhoto={node.frontmatter.authorPhoto?.publicURL}
                    />
                  </Section>
                );
              }

              if (node.frontmatter.slug === 'review') {
                return (
                  <Section
                    key={node.id}
                    id={`${node.frontmatter.slug}${i}`}
                    index={i}
                    getRef={this.getRef}
                    shown={i <= lastActiveIndex}
                  >
                    <Quote
                      title={node.frontmatter.title}
                      content={node.html}
                      source={node.frontmatter.source}
                    />
                  </Section>
                );
              }

              if (node.frontmatter.slug === 'videos') {
                return (
                  <Section
                    key={node.id}
                    id={node.frontmatter.slug}
                    index={i}
                    getRef={this.getRef}
                    shown={i <= lastActiveIndex}
                  >
                    <div
                      className={['container-fluid', styles.container].join(
                        ' '
                      )}
                    >
                      <div
                        className={['row no-gutters', styles.videosRow].join(
                          ' '
                        )}
                      >
                        {videos.edges.map(({ node }: any) => {
                          return (
                            <div
                              key={node.id}
                              className={['col-lg-4', styles.videoCol].join(
                                ' '
                              )}
                            >
                              <VideoTile
                                title={node.frontmatter.title}
                                videoId={node.frontmatter.videoId}
                                coverUrl={node.frontmatter.cover.publicURL}
                                onClick={(e) => {
                                  e.preventDefault();
                                  const newState = cloneDeep(this.state);
                                  newState.player.isShown = false;
                                  newState.videoModal.videoId =
                                    node.frontmatter.videoId;
                                  newState.videoModal.isShown = true;
                                  this.setState(newState);
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Section>
                );
              }

              if (node.frontmatter.slug === 'audios') {
                return (
                  <Section
                    key={node.id}
                    id={node.frontmatter.slug}
                    index={i}
                    getRef={this.getRef}
                    shown={i <= lastActiveIndex}
                  >
                    <div className={styles.audios}>
                      <div className="container-lg">
                        <div className="row justify-content-center">
                          <div className="col-lg-10">
                            <hr className={styles.divider} />
                            <div
                              className={[
                                'row justify-content-center',
                                styles.audiosRow,
                              ].join(' ')}
                            >
                              {audios.edges.map(({ node }: any) => {
                                return (
                                  <div
                                    key={node.id}
                                    className={[
                                      styles.audioCol,
                                      'col-6 col-md-4',
                                    ].join(' ')}
                                  >
                                    <AudioTile
                                      name={node.frontmatter.title}
                                      file={node.frontmatter.file.publicURL}
                                      onPlay={(name, file) => {
                                        const player = {
                                          isShown: true,
                                          name,
                                          file,
                                        };
                                        this.setState({
                                          player,
                                        });
                                      }}
                                      isPlaying={
                                        player.isShown &&
                                        node.frontmatter.title === player.name
                                      }
                                    />
                                  </div>
                                );
                              })}
                            </div>
                            <hr className={styles.divider} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Section>
                );
              }

              if (node.frontmatter.slug === 'gallery') {
                return (
                  <Section
                    key={node.id}
                    id={node.frontmatter.slug}
                    index={i}
                    getRef={this.getRef}
                    shown={i <= lastActiveIndex}
                  >
                    <Gallery
                      photos={galleryPhotos.edges}
                      thumbnails={galleryThumbnails.edges}
                    />
                  </Section>
                );
              }

              if (node.frontmatter.slug === 'contact') {
                return (
                  <Section
                    key={node.id}
                    id={node.frontmatter.slug}
                    index={i}
                    getRef={this.getRef}
                    shown={i <= lastActiveIndex}
                    animation="fadeIn"
                  >
                    <Contact />
                  </Section>
                );
              }
            })}
          </main>
          <CSSTransition
            in={player.isShown}
            timeout={150}
            classNames={{
              enter: playerStyles.enter,
              enterDone: playerStyles.enterDone,
              exitActive: playerStyles.exitActive,
            }}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <Player
                name={player.name}
                file={player.file}
                onClose={() => {
                  const newPlayer = cloneDeep(player);
                  newPlayer.isShown = false;
                  this.setState({
                    player: newPlayer,
                  });
                }}
              />
            </div>
          </CSSTransition>
          <CSSTransition
            in={this.state.videoModal.isShown}
            timeout={150}
            classNames={{
              enter: modalStyles.enter,
              enterDone: modalStyles.enterDone,
              exitActive: modalStyles.exitActive,
            }}
            mountOnEnter
            unmountOnExit
          >
            <Modal
              onHide={() => {
                const newVideoModal = cloneDeep(this.state.videoModal);
                newVideoModal.isShown = false;
                this.setState({
                  videoModal: newVideoModal,
                });
              }}
              isVideo
            >
              <iframe
                className="embed-responsive-item"
                src={`https://www.youtube.com/embed/${this.state.videoModal.videoId}?autoplay=1`}
                allowFullScreen
              ></iframe>
            </Modal>
          </CSSTransition>
          <footer className={styles.footer}>
            <p>Copyright © {site.siteMetadata.title}</p>
            <p>
              <small>
                <FormattedMessage id="cookiesPolicy" />
              </small>
            </p>
          </footer>
        </div>
      </IntlProvider>
    );
  }
}

export default Layout;
