import React, { FunctionComponent } from 'react';

import styles from './Quote.module.scss';

type Props = {
  title?: string;
  content: string;
  source: string;
  authorPhoto?: string;
};

const Quote: FunctionComponent<Props> = ({
  title,
  content,
  source,
  authorPhoto,
}) => (
  <div className={styles.root}>
    <div className="container-lg">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <h2 className={styles.title}>{title}</h2>
          <blockquote className={styles.quote}>
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <footer className={styles.quoteFooter}>{source}</footer>
          </blockquote>
        </div>
      </div>
    </div>
  </div>
);

export default Quote;
