import React, { Component, FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './Biography.module.scss';

type Props = {
  excerpt: string;
  content: string;
};

type State = {
  isExpanded: boolean;
  isTransiting: boolean;
};

class Biography extends Component<Props, State> {
  rootHeight = 0;
  state = {
    isExpanded: false,
    isTransiting: false,
  };

  render() {
    const rootStyles = [styles.root];
    const { isExpanded, isTransiting } = this.state;

    if (isExpanded) {
      rootStyles.push(styles.expanded);
    }

    if (isTransiting) {
      rootStyles.push(styles.hidden);
    }

    return (
      <div className={rootStyles.join(' ')}>
        <div className="container-lg">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              {isExpanded ? (
                <>
                  <div
                    dangerouslySetInnerHTML={{ __html: this.props.content }}
                  />
                  <div className="text-right">
                    <button
                      type="button"
                      className={styles.readMore}
                      onClick={() => {
                        this.setState({
                          isTransiting: true,
                        });

                        window.setTimeout(() => {
                          const element = document.getElementById('biography');
                          if (element) {
                            element.scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                              inline: 'start',
                            });
                          }

                          this.setState({
                            isExpanded: false,
                            isTransiting: false,
                          });
                        }, 300);
                      }}
                    >
                      <FormattedMessage id="readLess" />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div
                    dangerouslySetInnerHTML={{ __html: this.props.excerpt }}
                  />

                  <div className="text-right">
                    <button
                      type="button"
                      className={styles.readMore}
                      onClick={() => {
                        this.setState({
                          isTransiting: true,
                        });

                        window.setTimeout(() => {
                          const element = document.getElementById('biography');
                          if (element) {
                            element.scrollIntoView({
                              behavior: 'smooth',
                              block: 'start',
                              inline: 'start',
                            });
                          }

                          this.setState({
                            isExpanded: true,
                            isTransiting: false,
                          });
                        }, 300);
                      }}
                    >
                      <FormattedMessage id="readMore" />
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Biography;
