import React, { FunctionComponent, Component } from 'react';
import moment from 'moment';

import styles from './Player.module.scss';

type Props = {
  name?: string;
  file?: string;
  onClose: () => void;
};

type State = {
  isPlaying: boolean;
  currentTime: number;
  isFileLoaded: boolean;
  duration: number;
};

class AudioPlayer extends Component<Props, State> {
  audioElement: HTMLAudioElement;

  constructor(props: Props) {
    super(props);

    this.state = {
      isPlaying: false,
      currentTime: 0,
      isFileLoaded: false,
      duration: 0,
    };
    this.audioElement = new window.Audio();
  }

  componentDidMount() {
    const { name, file } = this.props;

    if (file) this.audioElement?.setAttribute('src', file);
    this.audioElement?.load();

    this.addEventListeners();
  }

  componentWillUnmount() {
    this.removeEventListeners();
    this.audioElement.pause();
    this.audioElement.currentTime = 0;
    this.audioElement.remove();
  }

  componentDidUpdate(prevProps: Props) {
    const { name, file } = this.props;

    if (prevProps.file !== file) {
      this.removeEventListeners();

      if (file) this.audioElement?.setAttribute('src', file);
      this.audioElement?.load();

      this.addEventListeners();
    }
  }

  addEventListeners() {
    this.audioElement?.addEventListener('loadeddata', this.onLoadedData);
    this.audioElement?.addEventListener(
      'canplaythrough',
      this.onCanPlayThrough
    );
    this.audioElement?.addEventListener('timeupdate', this.onTimeUpdate);
    this.audioElement?.addEventListener('ended', this.onEnded);
    this.audioElement?.addEventListener('play', this.onPlay);
    this.audioElement?.addEventListener('pause', this.onPause);
  }

  removeEventListeners() {
    this.audioElement?.removeEventListener('loadeddata', this.onLoadedData);
    this.audioElement?.removeEventListener(
      'canplaythrough',
      this.onCanPlayThrough
    );
    this.audioElement?.removeEventListener('timeupdate', this.onTimeUpdate);
    this.audioElement?.removeEventListener('ended', this.onEnded);
    this.audioElement?.removeEventListener('play', this.onPlay);
    this.audioElement?.removeEventListener('pause', this.onPause);
  }

  onLoadedData = (e: Event) => {
    this.setState({
      isFileLoaded: true,
      duration: Math.round((e.currentTarget as HTMLAudioElement).duration),
    });
  };

  onCanPlayThrough = (e: Event) => {
    (e.currentTarget as HTMLAudioElement).play();
    this.setState({ isPlaying: true });
  };

  onTimeUpdate = (e: Event) => {
    const currentTime = (e.currentTarget as HTMLAudioElement).currentTime;

    this.setState({ currentTime });
  };

  onEnded = (e: Event) => {
    this.props.onClose();
  };

  onPlay = (e: Event) => {
    this.setState({ isPlaying: true });
  };

  onPause = (e: Event) => {
    this.setState({ isPlaying: false });
  };

  render() {
    const { name, file, onClose } = this.props;
    const { isPlaying, currentTime, isFileLoaded, duration } = this.state;

    if (isFileLoaded) {
      return (
        <div className={styles.root}>
          <div
            className={styles.backdrop}
            onClick={(e) => {
              e.preventDefault();
              const clickedTime =
                (Math.round((e.clientX / e.currentTarget.offsetWidth) * 100) /
                  100) *
                duration;

              if (this.audioElement) {
                this.audioElement.currentTime = clickedTime;
              }
            }}
          ></div>
          <div
            className={styles.progress}
            style={{ width: `${(100 * currentTime) / duration}%` }}
          ></div>
          <div className={styles.content}>
            <div className={styles.name}>{name}</div>
            <div className={styles.controls}>
              {isPlaying ? (
                <button
                  type="button"
                  className={styles.control}
                  onClick={(e) => {
                    e.preventDefault();
                    this.audioElement?.pause();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    className={styles.icon}
                  >
                    <path d="M144 479H48c-26.5 0-48-21.5-48-48V79c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zm304-48V79c0-26.5-21.5-48-48-48h-96c-26.5 0-48 21.5-48 48v352c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48z" />
                  </svg>
                </button>
              ) : (
                <button
                  type="button"
                  className={styles.control}
                  onClick={(e) => {
                    e.preventDefault();
                    this.audioElement?.play();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    className={styles.icon}
                  >
                    <path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z" />
                  </svg>
                </button>
              )}
            </div>
            <div className={styles.time}>
              {moment().startOf('day').seconds(currentTime).format('mm:ss')}/
              {moment().startOf('day').seconds(duration).format('mm:ss')}
            </div>
          </div>
          <div className={styles.closeWrapper}>
            <button
              type="button"
              className={styles.close}
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                className={styles.closeIcon}
              >
                <path d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" />
              </svg>
            </button>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default AudioPlayer;
