import React, { FunctionComponent, useState } from 'react';
import { cloneDeep } from 'lodash';
import { CSSTransition } from 'react-transition-group';

import styles from './Gallery.module.scss';

import GalleryItem from './GalleryItem';
import Modal from '../modal/Modal';
import modalStyles from '../modal/Modal.module.scss';

type Props = {
  photos: any[];
  thumbnails: any[];
};

type GalleryModalState = {
  isShown: boolean;
  activeIndex: number;
};

const Gallery: FunctionComponent<Props> = ({ photos, thumbnails }) => {
  const [galleryModal, setGalleryModal] = useState<GalleryModalState>({
    isShown: false,
    activeIndex: -1,
  });

  const photo = photos[galleryModal.activeIndex];

  return (
    <>
      <div className={styles.root}>
        <div className={['container-fluid', styles.container].join(' ')}>
          <div className="row no-gutters">
            {photos.map(({ node }, index) => {
              return (
                <div key={index} className="col-lg-3">
                  <GalleryItem
                    photo={node.frontmatter.file.publicURL}
                    thumbnail={
                      thumbnails[index].node.frontmatter.file.childImageSharp
                        .fixed.src
                    }
                    title={
                      thumbnails[index].node.frontmatter.title !== null &&
                      thumbnails[index].node.frontmatter.title
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      const newGalleryModal = cloneDeep(galleryModal);
                      newGalleryModal.isShown = true;
                      newGalleryModal.activeIndex = index;
                      setGalleryModal(newGalleryModal);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <CSSTransition
        in={galleryModal.isShown}
        timeout={150}
        classNames={{
          enter: modalStyles.enter,
          enterDone: modalStyles.enterDone,
          exitActive: modalStyles.exitActive,
        }}
        mountOnEnter
        unmountOnExit
      >
        <Modal
          onHide={() => {
            const newGalleryModal = cloneDeep(galleryModal);
            newGalleryModal.isShown = false;
            newGalleryModal.activeIndex = -1;
            setGalleryModal(newGalleryModal);
          }}
          onPrev={() => {
            const newGalleryModal = cloneDeep(galleryModal);
            newGalleryModal.activeIndex = galleryModal.activeIndex - 1;
            setGalleryModal(newGalleryModal);
          }}
          onNext={() => {
            const newGalleryModal = cloneDeep(galleryModal);
            newGalleryModal.activeIndex = galleryModal.activeIndex + 1;
            setGalleryModal(newGalleryModal);
          }}
          canGoPrev={galleryModal.activeIndex > 0}
          canGoNext={galleryModal.activeIndex < photos.length - 1}
          title={
            photo &&
            photo.node.frontmatter.title !== null &&
            photo.node.frontmatter.title
          }
        >
          <img
            src={photo && photo.node.frontmatter.file.publicURL}
            alt={
              photo &&
              photo.node.frontmatter.title !== null &&
              photo.node.frontmatter.title
            }
            className={styles.photo}
          />
        </Modal>
      </CSSTransition>
    </>
  );
};

export default Gallery;
