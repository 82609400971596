import React, { FunctionComponent, MouseEvent } from 'react';

import styles from './GalleryItem.module.scss';

type Props = {
  photo: string;
  thumbnail: string;
  title?: string;
  onClick: (e: MouseEvent) => void;
};

const GalleryItem: FunctionComponent<Props> = ({
  photo,
  thumbnail,
  title,
  onClick,
}) => (
  <a href={photo} className={styles.root} onClick={onClick}>
    <img src={thumbnail} className={styles.photo} alt={title} />
  </a>
);

export default GalleryItem;
